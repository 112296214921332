import React from "react"
import RealizationInfo from "../../components/Organisms/RealizationInfo/RealizationInfo"
import RealizationPhotos from "../../components/Molecules/realizationPhotos/RealizationPhotos"
import { graphql } from "gatsby"
import photo1 from "../../assets/images/realizations/ustka/1.jpg"
import photo2 from "../../assets/images/realizations/ustka/2.jpg"
import photo3 from "../../assets/images/realizations/ustka/3.jpg"
import zambrow from "../../assets/images/realizations/zambrow/zambrow.jpg"
import gizycko from "../../assets/images/realizations/gizycko/gizycko.jpg"
import lomza from "../../assets/images/realizations/lomza/lomza.jpg"
import OtherRealizations from "../../components/Organisms/OtherRealizations/OtherRealizations"
import CTABanner from "../../components/Organisms/CTABanner/CTABanner"
import { WithIntl } from "../../components/Atoms/withTranslate"
import { localize } from "../../components/Atoms/Language"


const UstkaPage = ({ data, language }) => {
  const images = [photo1, photo2, photo3]
  const otherRealizations = [
    {
      image: zambrow,
      name: localize("290"),
      type: localize("186"),
      url: "zambrow",
    },
    {
      image: gizycko,
      name: localize("291"),
      type: localize("295"),
      url: "gizycko",
    },
    {
      image: lomza,
      name: localize("292"),
      type: localize("296"),
      url: "lomza",
    },
  ]

  return (
    <>
      <RealizationInfo open={1} closed={5} name={localize("289")} image={data.file.childImageSharp.fixed} language={language}>
        <li className="gray600">{localize("320")}</li>
        <li className="gray600">{localize("328")}</li>
        <li className="gray600">{localize("334")}</li>
        <li className="gray600">{localize("335")}</li>
      </RealizationInfo>
      <RealizationPhotos images={images}/>
      <OtherRealizations realizations={otherRealizations} language={language}/>
      <CTABanner language={language}/>
    </>
  )
}

export const query = graphql`
  {
    file(name: { eq: "ustka" }) {
      childImageSharp {
        fixed(width: 540, height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default WithIntl()(UstkaPage)
